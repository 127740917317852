import { Box, Stack, Typography } from '@mui/material';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/lib/hooks/useLocales';
import useResponsive from '#/lib/hooks/useResponsive';

type StepsIndicatorsProps = {
  activeStep: number;
  completedSteps?: number[];
};

const steps = [
  {
    index: 1,
    step: 'pensionProvision.onboarding.stepsIndicators.stepOne.step',
    title: 'pensionProvision.onboarding.stepsIndicators.stepOne.title',
    description:
      'pensionProvision.onboarding.stepsIndicators.stepOne.description',
  },
  {
    index: 2,
    step: 'pensionProvision.onboarding.stepsIndicators.stepTwo.step',
    title: 'pensionProvision.onboarding.stepsIndicators.stepTwo.title',
    description:
      'pensionProvision.onboarding.stepsIndicators.stepTwo.description',
  },
  {
    index: 3,
    step: 'pensionProvision.onboarding.stepsIndicators.stepThree.step',
    title: 'pensionProvision.onboarding.stepsIndicators.stepThree.title',
    description:
      'pensionProvision.onboarding.stepsIndicators.stepThree.description',
  },
];

export default function StepsIndicators({
  activeStep,
  completedSteps,
}: StepsIndicatorsProps) {
  const { translate } = useLocales();
  const isDesktop = useResponsive('up', 'md');

  return (
    <Stack
      direction={{
        xs: 'column',
        md: 'row',
      }}
      alignItems="center"
      justifyContent="center"
      spacing={6}
    >
      {steps.map((step) => (
        <Stack
          key={step.index}
          direction={{
            xs: 'column',
            md: 'row',
          }}
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Indicator
            key={step.index}
            isActive={activeStep === step.index}
            isCompleted={completedSteps?.includes(step.index)}
            title={translate(step.title)}
            description={translate(step.description)}
            step={translate(step.step)}
            index={step.index}
          />
          {step.index !== steps.length && (
            <Iconify
              icon={
                isDesktop ? 'mynaui:arrow-long-right' : 'mynaui:arrow-long-down'
              }
              width={55}
              height={5}
            />
          )}
        </Stack>
      ))}
    </Stack>
  );
}

type IndicatorProps = {
  isActive?: boolean;
  isCompleted?: boolean;
  index: number;
  step: string;
  title: string;
  description: string;
};

const Indicator = ({
  isActive,
  isCompleted,
  index,
  step,
  title,
  description,
}: IndicatorProps) => {
  const getBackgroundColor = () => {
    if (isActive) return 'primary.main';
    if (isCompleted) return 'success.light';
    return 'secondary.light';
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Box
        sx={{
          borderRadius: '50%',
          width: 80,
          height: 80,
          backgroundColor: getBackgroundColor(),
          opacity: isActive || isCompleted ? 1 : 0.2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Typography variant="h3" component="span" color="white">
          {index}
        </Typography>
        {isCompleted && !isActive && (
          <Iconify
            icon="ph:seal-check-fill"
            width={30}
            height={30}
            color="success.dark"
            sx={{ position: 'absolute', bottom: 0, right: 0 }}
          />
        )}
      </Box>

      <Typography variant="subtitle1">{step}</Typography>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="body1" color="text.secondary">
        {description}
      </Typography>
    </Stack>
  );
};
